import React, {Component} from "react"

import { 
  Button,
  GroupBox,
  Window,
  WindowContent,
  WindowHeader,
} from 'react95'

import Draggable from 'react-draggable';

import Icons from './Icons'

class WindowAbout extends Component {
	constructor(props){
		super(props)
		this.state = {
      items: [],
      opacity: 0,
    }
	}
  close() {
    this.props.close()
  }
  componentDidMount () {
    this.props.centerWindows();
    setTimeout(() => {
      this.props.centerWindows();
      this.setState(() => ({opacity: 1}))
    }, 150)
  }
  componentDidUpdate () {
    this.props.centerWindows()
  }
	render(){
    return (
      <Draggable bounds="parent" handle=".window-title">
        <Window style={{opacity: this.state.opacity}} className='about-window window center-window'>
          <WindowHeader className='window-title'>
            <div className="float-right">
              <Button onClick={this.close.bind(this)}>
                &times;
              </Button>
            </div>
            <span className="Help_16x16_4 window-icon "></span> <span> À propos d'OnlineCreation</span>
          </WindowHeader>
          <WindowContent>
            <div className="all-16 blue-text">
                <div className="float-left">
                  <i className="fak fa-onlinecreation fa-6x"></i>
                </div> 
                <div className="logo">
                  <span>Online</span>
                  <span>Creation</span>
                </div>
                <div className="float-right">
                  <a href="https://www.linkedin.com/company/onlinecreationme/" target="_blank" rel="noreferrer" className="blue-text"><i className="fa-brands fa-linkedin fa-2x right-16"></i></a>
                  <a href="https://www.facebook.com/onlinecreation.me/" target="_blank" rel="noreferrer" className="blue-text"><i className="fa-brands fa-facebook fa-2x right-16"></i></a>
                </div>
            </div>
            <div className="clearfix all-16">
              &nbsp;
              <p>
                OnlineCreation est l'agence web basée à Bordeaux qui vous accompagnera pour tous vos projets de développements connectés.
              </p>
            </div>
            <div className="flex-horizontal">
              <GroupBox label='Localisation' className="flex-1 all-16">
                <img src="https://static.onlc.eu/onlinecreationNDD/optimised/152736583435.png" alt="Basé à Bordeaux" />
                <p className="bottom-16">
                  <strong>Siège social</strong><br />
                  <span className="selectable">OnlineCreation</span><br />
                  <span className="selectable">61 rue du Château d'Eau</span><br/>
                  <span className="selectable">33000</span> <span className="selectable">Bordeaux</span><br />
                  <span className="selectable">France</span>
                </p>
                <p>
                  <strong>SARL au capital de 4&nbsp;500&nbsp;€</strong><br/>
                  <span className="selectable">520 540 188 R.C.S. Bordeaux</span><br/>
                  SIRET&nbsp;: <span className="selectable">52054018800031</span><br/>
                  Numéro de TVA&nbsp;: <span className="selectable">FR19520540188</span><br/>
                  Directeur de pub.&nbsp;: Raphaël Renaudon<br />
                  Hébergeur&nbsp;: OVH Cloud
                </p>
              </GroupBox>
              <div className="flex-1">
                <GroupBox label='Métiers' className="all-16">
                  <ul>
                    <li>• Création de <strong>sites web</strong></li>
                    <li>• <strong>Boutiques</strong> en ligne</li>
                    <li>• Conception d'<strong>applications</strong></li>
                    <li>• Création <strong>graphique</strong> UI/UX web</li>
                    <li>• <strong>Maintenance</strong> de projets web</li>
                  </ul>
                </GroupBox>
                <GroupBox label='Technologies' className="all-16">
                  {['wordpress', 'php', 'vuejs', 'html5', 'js', 'css3', 'debianlinux', 'symfony', 'onlc'].map((tech, index) => <Icons icon={tech} index={index} />)}
                </GroupBox>
                <GroupBox label='Crédits' className="all-16">
                  <p>
                    Site conçu avec <a href="https://reactjs.org/" target="_blank" rel="noreferrer"><i className="fa-brands fa-react"></i> React</a>, 
                    <a href="https://react95.io/" target="_blank" rel="noreferrer"> React 95</a>, <a href="https://github.com/React95/React95/tree/master/packages/icons" target="_blank" rel="noreferrer">React 95 icons</a> et <a href="https://fontawesome.com" target="_blank" rel="noreferrer">FontAwesome 6</a>.
                  </p>
                </GroupBox>
              </div>
            </div>
            <p className='align-right top-16'>
              <Button size='lg' className="buttonLg" onClick={this.close.bind(this)}>Fermer</Button>&nbsp;
              <Button size='lg' className="buttonLg" onClick={this.props.openContact.bind(this)} primary>Contacter OnlineCreation</Button>
            </p>
          </WindowContent>
        </Window>
      </Draggable>
    )
  }
}
export default WindowAbout;
